import React from 'react';
import { Link } from '@reach/router';
import { FormattedMessage } from '../shared';

import { Typography } from 'antd';

const { Paragraph, Title, Text } = Typography;

export const FaqForgotDevicePassword: React.FC = () => {
  const hereButtonLink = (
    <Link to="/landing">
      <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-link" />
    </Link>
  );

  return (
    <>
      <Title level={3}>
        <FormattedMessage id="app.faqs.device-password-reset.content.title" />
      </Title>
      <Paragraph style={{ marginTop: '2rem' }}>
        <Text strong>
          <FormattedMessage id="app.faqs.device-password-reset.content.bold-text-p1" />
        </Text>
        <Text strong style={{textDecoration: 'underline'}}>
          <FormattedMessage id="app.faqs.device-password-reset.content.underline-text-p1" />
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-1" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-2" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-3" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-4" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-5" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-6" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-7" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-8" />
        </Text>
      </Paragraph>
      <Paragraph style={{ marginTop: '2rem' }}>
        <Text strong>
          <FormattedMessage id="app.faqs.device-password-reset.content.bold-text-p2" />
        </Text>
        <Text strong style={{textDecoration: 'underline'}}>
          <FormattedMessage id="app.faqs.device-password-reset.content.underline-text-p1" />
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p2-content-1" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p2-content-2" />
        </Text>
        <br/>
        <Text>
          <FormattedMessage id="app.faqs.device-password-reset.content.p2-content-3" />
        </Text>
      </Paragraph>
    </>
  );
}
