import React from 'react';

import { FaqForgotDevicePassword } from './faq-forgot-device-password';
import { FaqRegisterDevice } from './faq-register-device';

interface FaqsProps {
    contentType: string;
}

export const FaqsContent: React.FC<FaqsProps> = (props) => {
    const { contentType } = props;

    const showContentHelp = (type: string) => {
        switch (type) {
            case 'forgot-device-password':
                return <FaqForgotDevicePassword />;
            case 'register-device':
                return <FaqRegisterDevice />;
            default:
                return <FaqForgotDevicePassword />;
        }
    }

    return (
        <>
            {showContentHelp(contentType)}
        </>
    )

}
