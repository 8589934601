import React, { useState } from "react"
import { Row, Col, Typography, Button, Divider } from "antd"

import { FormattedMessage } from "../components/shared"
import { FaqsContent } from "../components/faqs/faqs-content"

const { Title } = Typography

const FaqsPage: React.FC = () => {
  const [contentType, setContentType] = useState("")

  return (
    <Row>
      <Col sm={11} md={9} lg={8} xl={6} offset={1}>
        <Title level={2}>
          <FormattedMessage id="app.footer.faq.title" />
        </Title>
        <Button
          type="link"
          style={{ fontSize: "16px", marginTop: "1rem" }}
          onClick={() => setContentType("forgot-device-password")}
        >
          <FormattedMessage id="app.footer.faq-link.forgot-password" />
        </Button>
        <Divider></Divider>
        <Button
          type="link"
          style={{ fontSize: "16px" }}
          onClick={() => setContentType("register-device")}
        >
          <FormattedMessage id="app.footer.faq-link.register-device" />
        </Button>
        <Divider></Divider>
      </Col>
      <Col span={1}>
        {/* TODO: Make divider all the way down  */}
        {/* <Divider type='vertical'></Divider> */}
      </Col>
      <Col sm={11} md={13} lg={12} xl={15} offset={1}>
        <FaqsContent contentType={contentType} />
      </Col>
    </Row>
  )
}

export default FaqsPage
