import React from 'react';
import { Link } from '@reach/router';
import { FormattedMessage } from '../shared';

import { Typography } from 'antd';

const { Paragraph, Title, Text } = Typography;

export const FaqRegisterDevice: React.FC = () => (
  <>
    <Title level={3}>
      <FormattedMessage id="app.faqs.register-device.content.title" />
    </Title>
    <Paragraph>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p1" />
      </Text>
    </Paragraph>
    <Paragraph style={{ marginTop: '2rem' }}>
      <Text strong>
        <FormattedMessage id="app.faqs.register-device.content.bold-text-p1" />
      </Text>
      <Text strong style={{textDecoration: 'underline'}}>
        <FormattedMessage id="app.faqs.register-device.content.underline-text-p1" />
      </Text>
    </Paragraph>
    <Paragraph>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p1-content-1" />
      </Text>
      <br/>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p1-content-2" />
      </Text>
      <br/>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p1-content-3" />
      </Text>
    </Paragraph>
    <Paragraph style={{ marginTop: '2rem' }}>
      <Text strong>
        <FormattedMessage id="app.faqs.register-device.content.bold-text-p2" />
      </Text>
      <Text strong style={{textDecoration: 'underline'}}>
        <FormattedMessage id="app.faqs.register-device.content.underline-text-p1" />
      </Text>
    </Paragraph>
    <Paragraph>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p2-content-1" />
        <Link to="/landing">
          <FormattedMessage id="app.faqs.device-password-reset.content.p1-content-link" />
        </Link>
        <FormattedMessage id="app.faqs.register-device.content.p2-content-1.1" />
      </Text>
      <br/>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p2-content-2" />
      </Text>
      <br/>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p2-content-3" />
      </Text>
      <br/>
      <Text>
        <FormattedMessage id="app.faqs.register-device.content.p2-content-4" />
      </Text>
    </Paragraph>
  </>
)
